import { Carousel, Col, Row } from "react-bootstrap";

//images
import comprovante_bertolini from "../../../../Assets/comprovantes/comprovante_bertolini.png";
import comprovante_ldc from "../../../../Assets/comprovantes/comprovante_ldc.png";
import comprovante_rumo from "../../../../Assets/comprovantes/comprovante_rumo.png";
import comprovante_adm from "../../../../Assets/comprovantes/comprovante_adm.png";
import comprovante_rodoviva from "../../../../Assets/comprovantes/comprovante_rodoviva.png";
import comprovante_cte_rodoviva from "../../../../Assets/comprovantes/comprovante_cte_rodoviva.png";
import comprovante_ticket_pesagem from "../../../../Assets/comprovantes/comprovante_ticket_pesagem.png";
import comprovante_lazarotto from "../../../../Assets/comprovantes/comprovante_lazarotto.png";
import comprovante_canhoto from "../../../../Assets/comprovantes/comprovante_canhoto.png";
import comprovante_navegacao from "../../../../Assets/comprovantes/comprovante_navegacao.png";

import { ImgContainer, SliderContainer } from "./styles";

const ReceiptSelectorSlider = ({ index, setIndex, selectedReceiptModel }) => {
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <SliderContainer>
        <Carousel
          variant="dark"
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          controls={selectedReceiptModel === ""}
          indicators={selectedReceiptModel === ""}
          style={{ height: "340px" }}
        >
          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_bertolini" src={comprovante_bertolini} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_ldc" src={comprovante_ldc} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_rumo" src={comprovante_rumo} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_adm" src={comprovante_adm} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img
                alt="comprovante_ticket_pesagem"
                src={comprovante_ticket_pesagem}
              />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_lazarotto" src={comprovante_lazarotto} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img
                alt="comprovante_canhoto"
                src={comprovante_canhoto}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img
                alt="comprovante_cte_rodoviva"
                src={comprovante_cte_rodoviva}
              />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_navegacao" src={comprovante_navegacao} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_rodoviva" src={comprovante_rodoviva} />
            </ImgContainer>
          </Carousel.Item>
        </Carousel>
      </SliderContainer>
    </>
  );
};

export default ReceiptSelectorSlider;
